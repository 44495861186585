
type Props = {
  values?: string[]
}

export const Pics = ({
  values,
}: Props) => {
  return (
    <div className="flex justify-center mb-1">
      <div>
        <table className='table'>
          <tbody>
            <tr>
              <td colSpan={2}>
                <table>
                  <tbody>
                    <tr>
                      <td><img className='table-pic cover' alt='Pic1' src={values![0]}></img></td>
                      <td><img className='table-pic cover' alt='Pic1' src={values![1]}></img></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <table>
                  <tbody>
                    <tr>
                      <td><img className='table-pic cover' alt='Pic1' src={values![2]}></img></td>
                      <td><img className='table-pic cover' alt='Pic1' src={values![3]}></img></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}