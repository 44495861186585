import { FlagIcon } from "@heroicons/react/outline";
import { useState } from "react";
import ReactCountryFlag from "react-country-flag"
import { CountryDropdown } from 'react-country-region-selector';
import { MIN_POINTS_TO_START_PLEDGING } from "../../constants/settings";
import { loadIsPledgingAllowed, loadJwtTokenFromLocalStorage } from "../../lib/localStorage";
import { LoginModal } from "../modals/LoginModal";
import { regionNames } from "./CountriesLeaderBoard";

type Props = {
  onCountrySelected: (value: string) => void
  countryCode: string
}

export const AddYourScore = ({
  onCountrySelected, countryCode
}: Props) => {

  const [country, setCountry] = useState<string>(countryCode)
  const [disablePledgeBtn, setDisablePledgeBtn] = useState(loadIsPledgingAllowed())
  const [showLogin, setShowLogin] = useState(false)
  

  function pledgeToCountry() {

    if (country.trim() === '')
      return

    if (loadJwtTokenFromLocalStorage()) {
      countryCode = country
      onCountrySelected(country)
      setDisablePledgeBtn(true)
    } else {
      setShowLogin(true)
    }
  }

  return (
    <div>

      <ReactCountryFlag
        countryCode={country}
        style={{
          fontSize: '2em',
          lineHeight: '1em',
        }}
      />

      <CountryDropdown
        value={country}
        classes={"country-selector"}
        valueType="short"
        onChange={(val) => {

          if (val.trim() === '')
            setDisablePledgeBtn(true)
          else
            setDisablePledgeBtn(false)

          setCountry(val);
        }} />

      <button
        type="button"
        disabled={disablePledgeBtn || !loadIsPledgingAllowed()}
        className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm pledge-score-btn"
        onClick={async () => {
          pledgeToCountry()
        }}>

        <FlagIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" /> {(()=>{

          if (!loadIsPledgingAllowed()){
            return `Score above ${MIN_POINTS_TO_START_PLEDGING} points to start pledging`
          } else {
            return "Pledge points to " + ((country.trim() !== '') ? regionNames.of(country) : "country")
          }

        })()}
      </button>

      <LoginModal
        isOpen={showLogin}
        onLoginScucess={()=>{pledgeToCountry()}}
        handleClose={() => { setShowLogin(false) }}
      />

    </div>
  )
}