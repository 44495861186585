import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { COLORS } from '../../constants/colors';
import { HEROKU_API_URL } from '../../constants/settings';
import { loadJwtTokenFromLocalStorage, loadUserFromLocalStorage, saveUserToLocalStorage } from '../../lib/localStorage';
import { LoadingSVG } from '../pics/LoadingSVG';
import { AddYourScore } from './AddYourScore';

ChartJS.register(ArcElement, Tooltip, Legend);

export type DataSet = {
  label: string
  data: number[]
  backgroundColor: string[]
  borderColor: string[]
  borderWidth: number
}

export type LeaderBoardData = {
  labels: string[]
  datasets: DataSet[]
}

export const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const initialData: LeaderBoardData = {
  labels: ['Countries LeaderBoard'],
  datasets: [{
    label: '',
    data: [10],
    backgroundColor: ["#111111"],
    borderColor: ["#111111"],
    borderWidth: 1
  }]
}

const chartData: LeaderBoardData = {
  labels: [],
  datasets: [{
    label: '',
    data: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: 1
  }]
}

function getRandomColor(word: string) {
  let hash = hashCode(word)

  const color = COLORS[hash % COLORS.length]

  return color;
}

const hashCode = function (word: string) {
  var hash = 0, i, chr;
  if (word.length === 0) return hash;
  for (i = 0; i < word.length; i++) {
    chr = word.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

async function fetchLeaderBoardData() {

  const res = await fetch(HEROKU_API_URL + "/leaderboard", {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  })
  const data = await res.json()
  return data
}

async function createLeaderBoardData(callback: () => void) {

  const dataArr = await fetchLeaderBoardData()

  chartData.labels = []
  chartData.datasets[0].data = []
  chartData.datasets[0].backgroundColor = []
  chartData.datasets[0].borderColor = []

  dataArr.forEach((element: any) => {
    chartData.labels.push(regionNames.of(element.code)!)

    chartData.datasets[0].data.push(element.points)
    chartData.datasets[0].backgroundColor.push(getRandomColor(regionNames.of(element.code)!))
    chartData.datasets[0].borderColor.push(getRandomColor(regionNames.of(element.code)!))
  });

  callback()
}

export const CountriesLeaderBoard = () => {

  const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardData>(initialData)
  const [leaderBoardLoaded, setLeaderBoardLoaded] = useState(false)

  useEffect(() => {
    createLeaderBoardData(() => {
      setLeaderBoardData(chartData)
      setLeaderBoardLoaded(true)
    })
  }, [])



  async function onCountrySelected(newCountry: string) {
    const res = await fetch(HEROKU_API_URL + "/users/me", {
      method: "PATCH",
      body: JSON.stringify({
        country: newCountry
      }),
      headers: {
        "Authorization": "Bearer " + loadJwtTokenFromLocalStorage(),
        "Content-Type": "application/json"
      }
    })
    const data = await res.json()

    createLeaderBoardData(() => {

      const user = loadUserFromLocalStorage()

      if (user) {
        user.country = newCountry
        saveUserToLocalStorage(user)
      }


      if (data.country === newCountry) {
        alert(regionNames.of(newCountry) + " thanks you for your points!")
      }

      setLeaderBoardData(chartData)
      setLeaderBoardLoaded(false)
      setLeaderBoardLoaded(true)
    })
  }

  return <div>

    <div className='add-score'>
      <AddYourScore
        onCountrySelected={onCountrySelected}
        countryCode={(() => {
          const user = loadUserFromLocalStorage()
          if (user)
            return user.country || ''
          else
            return ''
        })()}
      />
    </div>

    <div >
      {
        (leaderBoardLoaded) ? (<Doughnut data={leaderBoardData!} />) : <LoadingSVG />
      }
    </div>

  </div>

}
