import { BaseModal } from './BaseModal'
import { Login } from '../login/Login'

type Props = {
  isOpen: boolean
  onLoginScucess: () => void
  handleClose: () => void
}

export const LoginModal = ({
  isOpen,
  onLoginScucess,
  handleClose,
}: Props) => {

  return (
    <BaseModal
      title={"Sign in to pledge points! 🏆"}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div>
        <Login onLoginScucess={onLoginScucess}/>
      </div>

    </BaseModal>
  )
}
