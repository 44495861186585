export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = false
export const BLOWFISH_KEY = 'yUfq0jssbub4ljmCfHWJJQQDB-j5uE'
export const BLOWFISH_IV = '0KLggE4G'
export const MAX_TOTAL_LIVES = 5
export const LIFE_REFILL_TIME = 1000 * 60 * 2
export const MIN_POINTS_TO_START_PLEDGING = 100
export const HEROKU_API_URL = "https://four-pics-1-wordle.herokuapp.com"
// export const HEROKU_API_URL = "http://localhost:3001"

