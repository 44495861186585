const gameStateKey = 'gameState'
const highContrastKey = 'highContrast'
const jwtTokenKey = 'jwtToken'
const pledgeCountryKey = 'pledgeCountry'
const userKey = 'user'
const scoresKey = 'scores'
const levelKey = 'level'
const isPledgingAllowedKey = 'allowPledgingKey'
const localObjectIdKey = 'localObjIdKey'

export type UserType = {
  name: string
  email: string
  country: string
  level: number
}

export type Score = {
  puzzleType: string,
  level: number,
  point: number,
  owner: string | null
}

export type StoredGameState = {
  guesses: string[]
  solution: string,
  unlimitedLives: number,
  unlimitedLivesSaveTime: number
}

export const saveLocalObjectId = (id: string)=>{
  localStorage.setItem(localObjectIdKey, id)
}

export const getLocalObjectId = (): string => {
  return localStorage.getItem(localObjectIdKey) || ''
}

export const loadIsPledgingAllowed = ():boolean => {
  const isPledgingAllowed = localStorage.getItem(isPledgingAllowedKey)

  return (isPledgingAllowed === 'true')
}

export const saveIsPledgingAllowed = (value: boolean)=> {
  if (value){
    localStorage.setItem(isPledgingAllowedKey, 'true')
  } else {
    localStorage.setItem(isPledgingAllowedKey, 'false')
  }
}

export const saveUserLevelToLocalStorage = (level: number)=>{
  localStorage.setItem(levelKey, level.toString())
}

export const loadUserLevelFromLocalStorage = () : number => {
  const level = localStorage.getItem(levelKey)

  return (level ? parseInt(level) : 0)
}

export const saveScoresToLocalStorage = (score: Score)=>{

  const scoresString = localStorage.getItem(scoresKey)

  const scores = scoresString ? JSON.parse(scoresString) : []

  scores.push(score)

  localStorage.setItem(scoresKey, JSON.stringify(scores))
}

export const loadScoresFromLocalStorage = () : Score[] =>{
  const scoresString = localStorage.getItem(scoresKey)
  
  const scores = scoresString ? JSON.parse(scoresString) : []

  return scores
}

export const deleteScoresFromLocalStorage= ()=>{
  localStorage.removeItem(scoresKey)
}

export const saveUserToLocalStorage = (user: UserType)=>{
  localStorage.setItem(userKey, JSON.stringify(user))
}

export const deleteUserFromLocalStorage = ()=>{
  localStorage.removeItem(userKey)
}

export const loadUserFromLocalStorage = () : UserType | null =>{
  const user = localStorage.getItem(userKey)
  return user ? (JSON.parse(user) as UserType) : null
}

export const loadTotalPointFromLocalStorage = () : number =>{

  const scores: Score[] = loadScoresFromLocalStorage()

  let totalPoints = 0

  if(scores.length === 0)
    return totalPoints

  scores.forEach((score)=>{

    if (score.puzzleType === "UNLIMITED_PUZZLE"){
      totalPoints += score.point
    } else {
      totalPoints += score.point * 25
    }
  })

  return  totalPoints
}

export const savePledgeCountryToLocalStorage = (country: string)=>{
  localStorage.setItem(pledgeCountryKey, country)
}

export const loadPledgeCountryFromLocalStorage = (): string =>{
  return localStorage.getItem(pledgeCountryKey) || ''
}


export const saveJwtTokenToLocalStorage = (token: string)=>{
  localStorage.setItem(jwtTokenKey, token)
}

export const loadJwtTokenFromLocalStorage = ():string => {
  const token = localStorage.getItem(jwtTokenKey)
  
  return token || ''
}


export const saveGameStateToLocalStorage = (gameState: StoredGameState) => {
  localStorage.setItem(gameStateKey, JSON.stringify(gameState))
}

export const loadGameStateFromLocalStorage = () => {
  const state = localStorage.getItem(gameStateKey)
  return state ? (JSON.parse(state) as StoredGameState) : null
}

const gameStatKey = 'gameStats'

export type GameStats = {
  winDistribution: number[]
  gamesFailed: number
  currentStreak: number
  bestStreak: number
  totalGames: number
  successRate: number
}

export const saveStatsToLocalStorage = (gameStats: GameStats) => {
  localStorage.setItem(gameStatKey, JSON.stringify(gameStats))
}

export const loadStatsFromLocalStorage = () => {
  const stats = localStorage.getItem(gameStatKey)
  return stats ? (JSON.parse(stats) as GameStats) : null
}

export const setStoredIsHighContrastMode = (isHighContrast: boolean) => {
  if (isHighContrast) {
    localStorage.setItem(highContrastKey, '1')
  } else {
    localStorage.removeItem(highContrastKey)
  }
}

export const getStoredIsHighContrastMode = () => {
  const highContrast = localStorage.getItem(highContrastKey)
  return highContrast === '1'
}
