import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login"
import { gapi } from 'gapi-script';
import { deleteScoresFromLocalStorage, getLocalObjectId, loadJwtTokenFromLocalStorage, loadScoresFromLocalStorage, saveJwtTokenToLocalStorage, saveUserLevelToLocalStorage, saveUserToLocalStorage, UserType } from "../../lib/localStorage";
import { HEROKU_API_URL } from "../../constants/settings";

declare global {
  interface Window {
  }
}

const REACT_PUBLIC_GOOGLE_CLIENT_ID = "473528520936-aj2smi49uhdp28j2ropk9vrfr1sit46a.apps.googleusercontent.com"

type Props = {
  onLoginScucess: () => void
}

export const Login = ({ onLoginScucess }: Props) => {


  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {

    const jwtToken = loadJwtTokenFromLocalStorage()

    if (jwtToken && jwtToken !== '') {
      setIsLoggedIn(true)
    }

    function start() {
      gapi.client.init({
        clientId: REACT_PUBLIC_GOOGLE_CLIENT_ID,
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const loginSuccess = async (googleData: any) => {

    const res = await fetch(HEROKU_API_URL+"/api/v1/auth/google", {
      method: "POST",
      body: JSON.stringify({
        token: googleData.tokenId,
        owner: getLocalObjectId()
      }),
      headers: {
        "Content-Type": "application/json"
      }
    })

    const data = await res.json()

    if (data.error) {
      return console.log("Error: ")
    }
    const user: UserType = {
      name: data.user.name,
      email: data.user.email,
      country: data.user.country,
      level: data.user.level
    }

    saveUserToLocalStorage(user)
    saveUserLevelToLocalStorage(user.level)

    saveJwtTokenToLocalStorage(data.jwtToken)
    await sendLocalScoresToAPI(data.jwtToken)

    setIsLoggedIn(true)
    onLoginScucess()
  }

  async function sendLocalScoresToAPI(jwt: string) {

    const scores = loadScoresFromLocalStorage()

    if (scores.length !== 0) {


      const res = await fetch(HEROKU_API_URL+"/allLocalScores", {
        method: "POST",
        body: JSON.stringify(scores),
        headers: {
          "Authorization": "Bearer " + jwt,
          "Content-Type": "application/json"
        }
      })

      const data = await res.json()

      console.log(data)

      if (data.error) {
        return console.log("Error: ")
      }

      const user: UserType = {
        name: data.user.name,
        email: data.user.email,
        country: data.user.country,
        level: data.user.level
      }

      saveUserToLocalStorage(user)
      saveUserLevelToLocalStorage(user.level)

      if (data.user) {
        deleteScoresFromLocalStorage()
      }
    }
  }

  return (


    (() => {
      if (!isLoggedIn) {
        return <GoogleLogin
          clientId={REACT_PUBLIC_GOOGLE_CLIENT_ID}
          buttonText="Sign in using Google"
          onSuccess={loginSuccess}
          className="login"
          onFailure={(response) => {
            console.log("Login failed", response);
          }}
          cookiePolicy={'single_host_origin'}
          isSignedIn={true}
        />
      } else {
        return <div>
          <h3 className="text-white">You've succesfuly signed in!</h3>
        </div>
      }
    })()


  )
}