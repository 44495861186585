export const WORDS = [
  "dealer",
  "speech",
  "lights",
  "eating",
  "course",
  "camera",
  "combat",
  "season",
  "design",
  "riding",
  "luxury",
  "silent",
  "palace",
  "dollar",
  "threat",
  "summer",
  "letter",
  "suffer",
  "screen",
  "forest",
  "French",
  "length",
  "rescue",
  "expert",
  "flying",
  "family",
  "mirror",
  "victim",
  "target",
  "vendor",
  "ethnic",
  "social",
  "winner",
  "fabric",
  "narrow",
  "writer",
  "driven",
  "launch",
  "number",
  "wealth",
  "friend",
  "player",
  "father",
  "string",
  "killed",
  "strong",
  "finish",
  "search",
  "effort",
  "valley",
  "injury",
  "reward",
  "eighth",
  "remote",
  "yellow",
  "window",
  "german",
  "square",
  "crisis",
  "driver",
  "church",
  "charge",
  "degree",
  "spread",
  "ground",
  "chance",
  "vision",
  "female",
  "stress",
  "server",
  "pocket",
  "column",
  "engage",
  "studio",
  "lesson",
  "handle",
  "safety",
  "planet",
  "mobile",
  "manual",
  "doctor",
  "winter",
  "market",
  "island",
  "moving",
  "corner",
  "finger",
  "stable",
  "marine",
]