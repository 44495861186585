import { useState, useEffect } from 'react'
import { Grid } from './components/grid/Grid'
import { Keyboard } from './components/keyboard/Keyboard'
import { InfoModal } from './components/modals/InfoModal'
import { StatsModal } from './components/modals/StatsModal'
import { SettingsModal } from './components/modals/SettingsModal'
import { Pics } from './components/pics/Pics'
import showPicturesLogo from './assets/show-pictures.png'
import { CurrentRow } from './components/grid/CurrentRow'
import CookieConsent from 'react-cookie-consent'

import { LoadingSVG } from './components/pics/LoadingSVG'

import {
  WIN_MESSAGES,
  GAME_COPIED_MESSAGE,
  NOT_ENOUGH_LETTERS_MESSAGE,
  WORD_NOT_FOUND_MESSAGE,
  CORRECT_WORD_MESSAGE,
  HARD_MODE_ALERT_MESSAGE,
  DISCOURAGE_INAPP_BROWSER_TEXT,
  UNLIMITED_MODE_TEXT as NEXT_PUZZLE_TEXT,
} from './constants/strings'
import {
  MAX_CHALLENGES,
  REVEAL_TIME_MS,
  WELCOME_INFO_MODAL_MS,
  DISCOURAGE_INAPP_BROWSERS,
  MAX_TOTAL_LIVES,
  LIFE_REFILL_TIME,
  HEROKU_API_URL,
} from './constants/settings'
import {
  isWordInWordList,
  isWinningWord,
  todaysSolution,
  findFirstUnusedReveal,
  unicodeLength,
  unlimitedWords,
  solutionIndex,
} from './lib/words'
import { addStatsForCompletedGame, loadStats } from './lib/stats'
import {
  loadGameStateFromLocalStorage,
  saveGameStateToLocalStorage,
  setStoredIsHighContrastMode,
  getStoredIsHighContrastMode,
  loadJwtTokenFromLocalStorage,
  UserType,
  Score,
  saveScoresToLocalStorage,
  loadUserLevelFromLocalStorage,
  saveUserLevelToLocalStorage,
  getLocalObjectId,
  saveLocalObjectId,
} from './lib/localStorage'
import { default as GraphemeSplitter } from 'grapheme-splitter'

import './App.css'
import { AlertContainer } from './components/alerts/AlertContainer'
import { useAlert } from './context/AlertContext'
import { Navbar } from './components/navbar/Navbar'
import { isInAppBrowser } from './lib/browser'
import { MigrateStatsModal } from './components/modals/MigrateStatsModal'

declare global {
  interface Window {
    consentGranted(): void
    gapi: any
  }
}

async function fetchPictures(
  setPictures: (a: string[]) => void,
  solution: string
) {
  const loadedPictures: string[] = []

  /* --------  PIXABAY API */

  // const IMAGE_API_URL = 'https://pixabay.com/api/?key=28672005-2b177fb9d0182b8209c49d58c&per_page=4' //&image_type=illustration'
  // const response = await fetch(`${IMAGE_API_URL}&q=${solution}`)

  // const data = await response.json()

  // data.hits.forEach((hit: any) => {
  //   if (loadedPictures.length < 4) loadedPictures.push(hit.webformatURL)
  // })

  /* --------  PIXABAY API */

  /* --------  PEXELS API */

  const PEXELS_API_KEY =
    '563492ad6f91700001000001690a668d1d6249a2866e239f3b2d0d25'
  const response = await fetch(
    `https://api.pexels.com/v1/search?query=${solution}&per_page=4`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: PEXELS_API_KEY,
      }),
    }
  )
  const data = await response.json()

  data.photos.forEach((photo: any) => {
    if (loadedPictures.length < 4) loadedPictures.push(photo.src.medium)
  })

  /* --------  PEXELS API */

  setPictures(loadedPictures)
}

function App() {
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches

  const { showError: showErrorAlert, showSuccess: showSuccessAlert } =
    useAlert()
  const [currentGuess, setCurrentGuess] = useState('')
  const [isGameWon, setIsGameWon] = useState(false)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false)
  const [isMigrateStatsModalOpen, setIsMigrateStatsModalOpen] = useState(false)
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false)
  const [currentRowClass, setCurrentRowClass] = useState('')
  const [isGameLost, setIsGameLost] = useState(false)
  const [isOriginalGameWon, setIsOriginalGameWon] = useState(isGameWon)
  const [isOriginalGameLost, setIsOriginalGameLost] = useState(isGameLost)
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem('theme')
      ? localStorage.getItem('theme') === 'dark'
      : prefersDarkMode
      ? true
      : false
  )
  const [isHighContrastMode, setIsHighContrastMode] = useState(
    getStoredIsHighContrastMode()
  )
  const [solution, setSolution] = useState<string>(todaysSolution)

  const [isRevealing, setIsRevealing] = useState(false)

  const [isUnlimitedModeOn, setIsUnlimitedModeOn] = useState(false)

  const [guesses, setGuesses] = useState<string[]>(() => {
    const loaded = loadGameStateFromLocalStorage()
    if (loaded?.solution !== todaysSolution) {
      return []
    }
    const gameWasWon = loaded.guesses.includes(todaysSolution)
    if (gameWasWon) {
      setIsGameWon(true)
      if (!isUnlimitedModeOn) {
        setIsOriginalGameWon(true)
      }
    }
    if (loaded.guesses.length === MAX_CHALLENGES && !gameWasWon) {
      setIsGameLost(true)
      if (!isUnlimitedModeOn) {
        setIsOriginalGameLost(true)
      }

      showErrorAlert(CORRECT_WORD_MESSAGE(todaysSolution), {
        persist: true,
      })
    }
    return loaded.guesses
  })

  const [originalGuesses, setOriginalGuesses] = useState<string[]>(guesses)

  const [stats, setStats] = useState(() => loadStats())

  const [isHardMode, setIsHardMode] = useState(
    localStorage.getItem('gameMode')
      ? localStorage.getItem('gameMode') === 'hard'
      : false
  )

  const [showPictures, setShowPictures] = useState(true)
  const [pictures, setPictures] = useState<string[]>([])

  const [unlimitedIndex, setUnlimitedIndex] = useState(
    loadUserLevelFromLocalStorage()
  )

  const [unlimitedLives, setUnlimitedLives] = useState(() => {
    const loaded = loadGameStateFromLocalStorage()

    if (loaded != null) {
      const timeElapsed = Date.now() - loaded.unlimitedLivesSaveTime
      const livesGenerated = timeElapsed / LIFE_REFILL_TIME
      const totalLives = loaded.unlimitedLives + livesGenerated

      return totalLives <= MAX_TOTAL_LIVES ? totalLives : MAX_TOTAL_LIVES
    }

    return MAX_TOTAL_LIVES
  })

  const [shouldShowNxtGameBtn, setShouldShowNxtGameBtn] = useState(false)
  const [jwtToken, setJwtToken] = useState<string | null>(null)
  const [user] = useState<UserType | null>(null)

  useEffect(() => {
    setPictures([])
    fetchPictures(setPictures, solution)
  }, [solution])

  useEffect(() => {
    if (!jwtToken) {
      setJwtToken(loadJwtTokenFromLocalStorage())
      if (jwtToken && jwtToken !== '') {
        fetchUser()
      }
    }
  }, [jwtToken])

  async function fetchUser() {
    const res = await fetch(HEROKU_API_URL + '/users/me', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await res.json()

    return data
  }

  useEffect(() => {
    // if no game state on load,
    // show the user the how-to info modal

    if (!loadGameStateFromLocalStorage()) {
      setTimeout(() => {
        setIsInfoModalOpen(true)
      }, WELCOME_INFO_MODAL_MS)
    }
  })

  useEffect(() => {
    DISCOURAGE_INAPP_BROWSERS &&
      isInAppBrowser() &&
      showErrorAlert(DISCOURAGE_INAPP_BROWSER_TEXT, {
        persist: false,
        durationMs: 7000,
      })
  }, [showErrorAlert])

  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }

    if (isHighContrastMode) {
      document.documentElement.classList.add('high-contrast')
    } else {
      document.documentElement.classList.remove('high-contrast')
    }
  }, [isDarkMode, isHighContrastMode])

  const handleDarkMode = (isDark: boolean) => {
    setIsDarkMode(isDark)
    localStorage.setItem('theme', isDark ? 'dark' : 'light')
  }

  const handleHardMode = (isHard: boolean) => {
    if (guesses.length === 0 || localStorage.getItem('gameMode') === 'hard') {
      setIsHardMode(isHard)
      localStorage.setItem('gameMode', isHard ? 'hard' : 'normal')
    } else {
      showErrorAlert(HARD_MODE_ALERT_MESSAGE)
    }
  }

  const handleHighContrastMode = (isHighContrast: boolean) => {
    setIsHighContrastMode(isHighContrast)
    setStoredIsHighContrastMode(isHighContrast)
  }

  const clearCurrentRowClass = () => {
    setCurrentRowClass('')
  }

  useEffect(() => {
    saveGameStateToLocalStorage({
      guesses: originalGuesses,
      solution: todaysSolution,
      unlimitedLives,
      unlimitedLivesSaveTime: Date.now(),
    })
  }, [originalGuesses, unlimitedLives])

  useEffect(() => {
    if (isUnlimitedModeOn)
      setSolution(unlimitedWords[unlimitedIndex % unlimitedWords.length])
    saveUserLevelToLocalStorage(unlimitedIndex)
  }, [unlimitedIndex, isUnlimitedModeOn])

  useEffect(() => {
    if (!isUnlimitedModeOn) {
      setIsOriginalGameLost(isGameLost)
      setIsOriginalGameWon(isGameWon)
      setOriginalGuesses(guesses)
    }

    if (isGameWon) {
      const winMessage =
        WIN_MESSAGES[Math.floor(Math.random() * WIN_MESSAGES.length)]
      const delayMs = REVEAL_TIME_MS * solution.length

      showSuccessAlert(winMessage, {
        delayMs,
        onClose: () => {
          setIsStatsModalOpen(true)
          setShouldShowNxtGameBtn(true)
        },
      })
    }

    if (isGameLost) {
      setTimeout(() => {
        setIsStatsModalOpen(true)
        setShouldShowNxtGameBtn(true)
      }, (solution.length + 1) * REVEAL_TIME_MS)
    }
  }, [
    isGameWon,
    isGameLost,
    showSuccessAlert,
    guesses,
    isUnlimitedModeOn,
    solution.length,
  ])

  const onChar = (value: string) => {
    if (
      unicodeLength(`${currentGuess}${value}`) <= solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setCurrentGuess(`${currentGuess}${value}`)
    }
  }

  const onDelete = () => {
    setCurrentGuess(
      new GraphemeSplitter().splitGraphemes(currentGuess).slice(0, -1).join('')
    )
  }

  const onEnter = () => {
    setShowPictures(false)

    if (isGameWon || isGameLost) {
      return
    }

    if (!(unicodeLength(currentGuess) === solution.length)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(NOT_ENOUGH_LETTERS_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    if (!isWordInWordList(currentGuess)) {
      setCurrentRowClass('jiggle')
      return showErrorAlert(WORD_NOT_FOUND_MESSAGE, {
        onClose: clearCurrentRowClass,
      })
    }

    // enforce hard mode - all guesses must contain all previously revealed letters
    if (isHardMode) {
      const firstMissingReveal = findFirstUnusedReveal(
        currentGuess,
        guesses,
        solution
      )
      if (firstMissingReveal) {
        setCurrentRowClass('jiggle')
        return showErrorAlert(firstMissingReveal, {
          onClose: clearCurrentRowClass,
        })
      }
    }

    setIsRevealing(true)
    // turn this back off after all
    // chars have been revealed
    setTimeout(() => {
      setIsRevealing(false)
    }, REVEAL_TIME_MS * solution.length)

    const winningWord = isWinningWord(currentGuess, solution)

    if (
      unicodeLength(currentGuess) === solution.length &&
      guesses.length < MAX_CHALLENGES &&
      !isGameWon
    ) {
      setGuesses([...guesses, currentGuess])

      setCurrentGuess('')

      if (winningWord) {
        setStats(addStatsForCompletedGame(stats, guesses.length))

        const points = 6 - guesses.length

        const score: Score = {
          puzzleType: isUnlimitedModeOn ? 'UNLIMITED_PUZZLE' : 'DAILY_PUZZLE',
          level: isUnlimitedModeOn ? unlimitedIndex : solutionIndex,
          point: points,
          owner: null,
        }

        let jwt = loadJwtTokenFromLocalStorage()

        if (jwt) {
          sendScoreToAPI(score, jwt)
        } else {
          saveScoresToLocalStorage(score)
          sendScoreToAPIAnonymous(score)
        }

        return setIsGameWon(true)
      }

      if (guesses.length === MAX_CHALLENGES - 1) {
        setStats(addStatsForCompletedGame(stats, guesses.length + 1))
        setIsGameLost(true)

        showErrorAlert(CORRECT_WORD_MESSAGE(solution), {
          persist: true,
          delayMs: REVEAL_TIME_MS * solution.length + 1,
        })
      }
    }
  }

  async function sendScoreToAPI(score: Score, jwt: string) {
    await fetch(HEROKU_API_URL + '/scores', {
      method: 'POST',
      body: JSON.stringify({
        puzzleType: score.puzzleType,
        level: score.level,
        point: score.point,
      }),
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    })
  }

  async function sendScoreToAPIAnonymous(score: Score) {
    const objectId = getLocalObjectId()

    if (objectId.trim() !== '') {
      score.owner = objectId
    }

    const fetchCountry: any = await fetch('https://ipapi.co/json/', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const countryData = await fetchCountry.json()

    const res = await fetch(HEROKU_API_URL + '/scoresAnonymous', {
      method: 'POST',
      body: JSON.stringify({
        ...score,
        country: countryData.country_code,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const data = await res.json()

    if (data.owner) {
      saveLocalObjectId(data.owner)
    }
  }

  const onShowPictureClicked = () => {
    setShowPictures((showPictures) => !showPictures)
  }

  const handleUnlimitedMode = () => {
    if (isUnlimitedModeOn) {
      setUnlimitedIndex((index) => index + 1)
    }

    if (!isUnlimitedModeOn) setIsUnlimitedModeOn(true)

    if (isGameWon) setIsGameWon(false)

    if (isGameLost) setIsGameLost(false)

    setGuesses([])

    setCurrentGuess('')

    setShowPictures(true)

    if (shouldShowNxtGameBtn) setShouldShowNxtGameBtn(false)
  }

  return (
    <div className="h-screen flex flex-col">
      <Navbar
        setIsInfoModalOpen={setIsInfoModalOpen}
        setIsStatsModalOpen={setIsStatsModalOpen}
        setIsSettingsModalOpen={setIsSettingsModalOpen}
      />
      <div className="pt-2 px-1 pb-8 md:max-w-7xl w-full mx-auto sm:px-6 lg:px-8 flex flex-col grow">
        <div className="pb-6 grow">
          {showPictures ? (
            (() => {
              if (pictures.length === 0) return <LoadingSVG />
              else {
                return <Pics values={pictures} />
              }
            })()
          ) : (
            <Grid
              solution={solution}
              guesses={guesses}
              currentGuess={currentGuess}
              isRevealing={isRevealing}
              currentRowClassName={currentRowClass}
            />
          )}
          {
            <div className="flex justify-center mb-1">
              <div className="letter-container show-picture-btn">
                <img
                  alt="A cat"
                  src={showPicturesLogo}
                  onClick={() => onShowPictureClicked()}
                ></img>
              </div>
            </div>
          }

          {(() => {
            if (shouldShowNxtGameBtn && !isStatsModalOpen) {
              return (
                <div className="flex justify-center mb-1">
                  <div className="unlimited-mode-button">
                    <button
                      type="button"
                      className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-600 text-lg font-medium text-white text-capitalize hover:bg-lime-700  sm:text-xl sm:text-capitalize"
                      onClick={() => {
                        if (Math.floor(unlimitedLives) > 0) {
                          handleUnlimitedMode()
                          setUnlimitedLives(unlimitedLives - 1)
                        } else {
                          alert('Not enough lives')
                        }
                      }}
                    >
                      {NEXT_PUZZLE_TEXT}
                    </button>
                  </div>
                </div>
              )
            } else {
              return (
                <div className="bottom-current-guess">
                  <CurrentRow
                    guess={currentGuess}
                    className={currentRowClass}
                  />
                </div>
              )
            }
          })()}
        </div>

        <Keyboard
          onChar={onChar}
          onDelete={onDelete}
          onEnter={onEnter}
          solution={solution}
          guesses={guesses}
          isRevealing={isRevealing}
        />
        <InfoModal
          isOpen={isInfoModalOpen}
          handleClose={() => setIsInfoModalOpen(false)}
        />
        <StatsModal
          isOpen={isStatsModalOpen}
          handleUnlimitedMode={handleUnlimitedMode}
          unlimitedLives={(() => {
            const loaded = loadGameStateFromLocalStorage()
            if (
              loaded &&
              loaded.unlimitedLives &&
              loaded.unlimitedLivesSaveTime
            ) {
              const timeElapsed = Date.now() - loaded.unlimitedLivesSaveTime
              const livesGenerated = timeElapsed / LIFE_REFILL_TIME

              const totalLives = loaded.unlimitedLives + livesGenerated

              return totalLives <= MAX_TOTAL_LIVES
                ? totalLives
                : MAX_TOTAL_LIVES
            }

            return MAX_TOTAL_LIVES
          })()}
          setUnlimitedLives={(value) => {
            setUnlimitedLives(value)
          }}
          handleClose={() => setIsStatsModalOpen(false)}
          solution={todaysSolution}
          guesses={originalGuesses}
          gameStats={stats}
          isGameLost={isOriginalGameLost}
          isGameWon={isOriginalGameWon}
          handleShareToClipboard={() => showSuccessAlert(GAME_COPIED_MESSAGE)}
          handleMigrateStatsButton={() => {
            setIsStatsModalOpen(false)
            setIsMigrateStatsModalOpen(true)
          }}
          isHardMode={isHardMode}
          isDarkMode={isDarkMode}
          isHighContrastMode={isHighContrastMode}
          numberOfGuessesMade={originalGuesses.length}
          user={user}
        />
        <MigrateStatsModal
          isOpen={isMigrateStatsModalOpen}
          handleClose={() => setIsMigrateStatsModalOpen(false)}
        />
        <SettingsModal
          isOpen={isSettingsModalOpen}
          handleClose={() => setIsSettingsModalOpen(false)}
          isHardMode={isHardMode}
          handleHardMode={handleHardMode}
          isDarkMode={isDarkMode}
          handleDarkMode={handleDarkMode}
          isHighContrastMode={isHighContrastMode}
          handleHighContrastMode={handleHighContrastMode}
        />
        <AlertContainer />

        <CookieConsent
          location="bottom"
          buttonText="Ok!"
          style={{ background: '#2B373B', textAlign: 'center' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling) {
              // triggered if user scrolls past threshold
              window.consentGranted()
            } else {
              window.consentGranted()
            }
          }}
        >
          <span style={{ fontSize: '15px', textAlign: 'center' }}>
            {' '}
            This website uses cookies to enhance the user experience.
          </span>
        </CookieConsent>
      </div>
    </div>
  )
}

export default App
