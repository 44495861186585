import { useEffect, useState } from 'react'
import { HEROKU_API_URL, MIN_POINTS_TO_START_PLEDGING } from '../../constants/settings'
import { loadJwtTokenFromLocalStorage, loadTotalPointFromLocalStorage, loadUserFromLocalStorage, saveIsPledgingAllowed } from '../../lib/localStorage'

const StatItem = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => {
  return (
    <div className="items-center justify-center m-1 w-1/4 dark:text-white">
      <div className="text-3xl font-bold">{value}</div>
      <div className="text-base">{label}</div>
    </div>
  )
}

export const ScoreBar = () => {

  const [totalPoints, setTotalPoints] = useState(0)

  useEffect(() => {
    getTotalPointsFromRemoteSource()

  }, [])

  useEffect(()=>{

    if (totalPoints >= MIN_POINTS_TO_START_PLEDGING){
      saveIsPledgingAllowed(true)
    }
  }, [totalPoints])

  async function getTotalPointsFromRemoteSource() {

    const locallyStoredScoresPoint = loadTotalPointFromLocalStorage()

    setTotalPoints(locallyStoredScoresPoint)

    const jwt = loadJwtTokenFromLocalStorage()
    if (jwt) {
      const res = await fetch(HEROKU_API_URL+"/totalScore", {
        method: "GET",
        headers: {
          "Authorization": "Bearer " + jwt,
          "Content-Type": "application/json"
        }
      })

      const data = await res.json()

      if (data.totalPoints)
        setTotalPoints(data.totalPoints + locallyStoredScoresPoint)
    }
  }

  return (
    <div>
      <div>
        <p className='text-white text-lg'>Welcome, {(()=>{
          const user = loadUserFromLocalStorage()
          if (user)
            return user.name.slice(0, user.name.indexOf(' '))
          else
            return "Stranger"
          })()}</p>
      </div>
      <div className="flex justify-center my-2">
        <StatItem label={"Your Points"} value={(totalPoints)} />
      </div>
      <div>
        <p className='text-xs text-gray-500'>Daily puzzles = 25 - 150 points <br></br> Next puzzles = 1 - 6 points</p>
      </div>
    </div>
  )
}
