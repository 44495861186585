import {useState } from "react"
import Countdown from "react-countdown"
import { LIFE_REFILL_TIME, MAX_TOTAL_LIVES } from "../../constants/settings"

type Props = {
  lives: number,
  setUnlimitedLives: (value:number)=>void
}

export const LivesModal = ({
  lives,
  setUnlimitedLives,
}: Props) => {


  var currentLives = lives

  const lifeIcon = " 💚 "
  const emptyIcon = " 🤍 "
// 🤍💚🧡💛
  
  const createLivesEmoji = () => {
    let livesEmoji = ""

    for (let i = 0; i < MAX_TOTAL_LIVES; i++) {
      livesEmoji = livesEmoji + (i < Math.floor(currentLives) ? lifeIcon : emptyIcon)
    }

    return livesEmoji
  }

  const [countDownTime, setCountDownTime] = useState((() => {
    if (currentLives < MAX_TOTAL_LIVES)
      return Date.now() +  ((Math.ceil(currentLives) - currentLives) * LIFE_REFILL_TIME)
    else
      return Date.now()
  })())
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0);

  // useEffect(()=>{
  //   setUnlimitedLives(currentLives)
  // }, [currentLives, setUnlimitedLives])

  return (
    <div>
      <h4 className="text-base leading-6 font-small">{(() =>  createLivesEmoji() )()}</h4>
      <h4 className="text-base leading-6 font-small text-gray-900 dark:text-gray-100"><Countdown
        className="text-base font-medium text-gray-900 dark:text-gray-100"
        date={countDownTime}
        key={currentTimeIndex}
        daysInHours={true}
        onComplete={() => {
          if (currentLives < 9) {
            setCurrentTimeIndex(currentTimeIndex + 1);
            setCountDownTime(Date.now() + (LIFE_REFILL_TIME))
          }
          currentLives += 1
          setUnlimitedLives(currentLives)
        }}
        renderer={({ hours, minutes, seconds, completed }) => {
          // render completed
          if (completed) return <span>{"\n"}</span>;
          // render current countdown time
          return <span>{minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}:{seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}</span>;

      }}
      />
      </h4>
    </div>
  )
}