import { BaseModal } from './BaseModal'
import { SettingsToggle } from './SettingsToggle'
import {
  HARD_MODE_DESCRIPTION,
  HIGH_CONTRAST_MODE_DESCRIPTION,
} from '../../constants/strings'
import  { GoogleLogout } from 'react-google-login'
import {  deleteUserFromLocalStorage, saveJwtTokenToLocalStorage } from '../../lib/localStorage'
import { Login } from '../login/Login'

const REACT_PUBLIC_GOOGLE_CLIENT_ID = "473528520936-aj2smi49uhdp28j2ropk9vrfr1sit46a.apps.googleusercontent.com"

type Props = {
  isOpen: boolean
  handleClose: () => void
  isHardMode: boolean
  handleHardMode: Function
  isDarkMode: boolean
  handleDarkMode: Function
  isHighContrastMode: boolean
  handleHighContrastMode: Function
}

export const SettingsModal = ({
  isOpen,
  handleClose,
  isHardMode,
  handleHardMode,
  isDarkMode,
  handleDarkMode,
  isHighContrastMode,
  handleHighContrastMode,
}: Props) => {
  return (
    <BaseModal title="Settings" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 divide-y">
        <SettingsToggle
          settingName="Hard Mode"
          flag={isHardMode}
          handleFlag={handleHardMode}
          description={HARD_MODE_DESCRIPTION}
        />
        <SettingsToggle
          settingName="Dark Mode"
          flag={isDarkMode}
          handleFlag={handleDarkMode}
        />
        <SettingsToggle
          settingName="High Contrast Mode"
          flag={isHighContrastMode}
          handleFlag={handleHighContrastMode}
          description={HIGH_CONTRAST_MODE_DESCRIPTION}
        />

        <Login onLoginScucess={() => { }} />


        <GoogleLogout
          clientId={REACT_PUBLIC_GOOGLE_CLIENT_ID}
          buttonText="Sign out"
          onLogoutSuccess={() => {
            saveJwtTokenToLocalStorage('')
            deleteUserFromLocalStorage()
            alert("You've successfully signed out")
          }}
        />
      </div>
    </BaseModal>
  )
}
