import Countdown from 'react-countdown'
import { ShareIcon } from '@heroicons/react/outline'
import { GameStats, UserType } from '../../lib/localStorage'
import { shareStatus } from '../../lib/share'
import { tomorrow } from '../../lib/words'
import { BaseModal } from './BaseModal'
import {
  NEW_WORD_TEXT,
  SHARE_TEXT,
  UNLIMITED_MODE_TEXT
} from '../../constants/strings'
import { MigrationIntro } from '../stats/MigrationIntro'
import { ENABLE_MIGRATE_STATS } from '../../constants/settings'
import { LivesModal } from './LivesModal'
import { CountriesLeaderBoard } from '../leaderboards/CountriesLeaderBoard'
import { ScoreBar } from '../stats/ScoreBar'

type Props = {
  isOpen: boolean
  handleUnlimitedMode: () => void
  unlimitedLives: number
  setUnlimitedLives: (value: number) => void
  handleClose: () => void
  solution: string
  guesses: string[]
  gameStats: GameStats
  isGameLost: boolean
  isGameWon: boolean
  handleShareToClipboard: () => void
  handleMigrateStatsButton: () => void
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  numberOfGuessesMade: number
  user: UserType | null
}

export const StatsModal = ({
  isOpen,
  handleClose,
  handleUnlimitedMode,
  unlimitedLives,
  setUnlimitedLives,
  solution,
  guesses,
  gameStats,
  isGameLost,
  isGameWon,
  handleShareToClipboard,
  handleMigrateStatsButton,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  numberOfGuessesMade,
  user
}: Props) => {
 
  return (
    <BaseModal
      title={""}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <ScoreBar />

      <div className='unlimited-mode-button'>
        <button
          type="button"
          className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-lime-600 text-lg font-medium text-white text-capitalize hover:bg-lime-700  sm:text-xl sm:text-capitalize"
          onClick={() => {
            if (Math.floor(unlimitedLives) > 0) {
              handleClose()
              handleUnlimitedMode()
              setUnlimitedLives(unlimitedLives - 1)
            } else {
              alert("Not enough lives")
            }
          }}
        >
          <div>
            <div>
              {UNLIMITED_MODE_TEXT}
            </div>
            <div>
              <LivesModal
                lives={unlimitedLives}
                setUnlimitedLives={setUnlimitedLives}
              />
            </div>
          </div>

        </button>
      </div>

      <div className='countries-leader-board'>
      <CountriesLeaderBoard/>
      </div>
      
      {(isGameLost || isGameWon) && (
        <div className="mt-5 sm:mt-6 columns-2 dark:text-white">
          <div>
            <h5>{NEW_WORD_TEXT}</h5>
            <Countdown
              className="text-lg font-medium text-gray-900 dark:text-gray-100"
              date={tomorrow}
              daysInHours={true}
            />
          </div>
          <div>
            <button
              type="button"
              className="inline-flex justify-center items-center text-center mt-2 w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() => {
                shareStatus(
                  solution,
                  guesses,
                  isGameLost,
                  isHardMode,
                  isDarkMode,
                  isHighContrastMode,
                  handleShareToClipboard
                )
              }}
            >
              <ShareIcon className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white" />
              {SHARE_TEXT}
            </button>
          </div>
        </div>
      )}
      {ENABLE_MIGRATE_STATS && (
        <div>
          <hr className="mt-4 -mb-4 border-gray-500" />
          <MigrationIntro handleMigrateStatsButton={handleMigrateStatsButton} />
        </div>
      )}
    </BaseModal>
  )
}
